<template>
  <div>
    <a id="marketing-block" class="relative" href="#" style=" top: -85px" />
    <SectionTitle hideBtn title="行銷圖片" />

    <el-table :data="banners">
      <el-table-column label="類型" align="center">
        <template slot-scope="scope">
          <p>{{ scope.$index ? '其他' : '首圖' }}</p>
        </template>
      </el-table-column>
      <el-table-column label="網頁版" align="center">
        <template slot-scope="scope">
          <img :src="imgUrl('desktop',scope.row)" alt="" srcset="">
        </template>
      </el-table-column>
      <el-table-column label="手機版" align="center">
        <template slot-scope="scope">
          <img :src="imgUrl('mobile', scope.row)" alt="" srcset="">
        </template>
      </el-table-column>
      <el-table-column label="連結分類" align="center">
        <template slot-scope="scope">
          <p>{{ typeDict(scope.row) }}</p>
        </template>
      </el-table-column>
      <el-table-column label="使用類別" align="center">
        <template slot-scope="scope">
          <p>{{ cateActivityDict(scope.row) }}</p>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { get } from 'lodash'
import { imgSrc } from '@/utils/helper'

export default {
  name: 'BannerViewBlock',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    banners () {
      return get(this.data, 'marketing.carousel', [])
    },
  },
  methods: {
    imgUrl (type, row) {
      let img
      if (type === 'mobile') img = get(row, 'responsiveImage.phoneSizeImage', null)
      if (type === 'desktop') img = get(row, 'responsiveImage.webSizeImage', null)
      return imgSrc(150, img)
    },
    typeDict (row) {
      const type = get(row, 'universalLink.type', '')
      if (!type) return ''
      const dict = {
        ecommerceCategory: '類別',
        ecommerceActivity: '活動',
      }
      return dict[type]
    },
    cateActivityDict (row) {
      const type = get(row, 'universalLink.type', '')
      if (type === 'ecommerceCategory') {
        return get(row, 'universalLink.ecommerceCategory.name', '')
      }
      if (type === 'ecommerceActivity') {
        return get(row, 'universalLink.ecommerceActivity.name', '')
      }
    },
  },
}
</script>
