<template>
  <main class="ecommerce-home-setting">
    <PageTitle style="margin-bottom: 35px" title="首頁設定" @btnClick="goSetting" />
    <EcommerceHomeAnchorTabs />

    <div v-if="homePageConfig" class="block-list">
      <BannerBlock :data="getConfig('banner')" />
      <ProductListBlock :data="getConfig('productList')" />
      <RecommandBlock :data="getConfig('productTypeList')" />
      <MediaReportBlock :data="getConfig('news')" />
      <TextStoryBlock :data="getConfig('text')" />
      <MarketingBlock :data="getConfig('marketing')" />
      <YoutubeBlock :data="getConfig('youtube')" />
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import { forEach, find } from 'lodash'
import EcommerceHomeAnchorTabs from '@/components/Tabs/EcommerceHomeAnchorTabs.vue'
import BannerBlock from './components/BannerBlock.vue'
import ProductListBlock from './components/ProductListBlock.vue'
import RecommandBlock from './components/RecommandBlock.vue'
import MediaReportBlock from './components/MediaReportBlock.vue'
import TextStoryBlock from './components/TextStoryBlock.vue'
import MarketingBlock from './components/MarketingBlock.vue'
import YoutubeBlock from './components/YoutubeBlock.vue'

export default {
  name: 'HomeSetting',
  components: {
    // MemberPageTabs,
    BannerBlock,
    ProductListBlock,
    RecommandBlock,
    MediaReportBlock,
    TextStoryBlock,
    MarketingBlock,
    YoutubeBlock,
    EcommerceHomeAnchorTabs,
  },

  data: () => ({
    disableUpdate: false,
    loaded: false,
    activeTab: 'banner',
    areas: {
      banner: {},
      productList: {},
      recommand: {},
      mediaReport: {},
      textStory: {},
      marketing: {},
      youtubeLink: {},
    },
  }),
  computed: {
    ...mapGetters(['shop', 'ecommerceHomePageConfig']),
    activitys () {
      return this.$store.state['ecommerce-home'].activitys
    },
    categorys () {
      return this.$store.state['ecommerce-home'].categorys
    },
    homePageConfig () {
      return this.ecommerceHomePageConfig
    },
    compactData () {
      const content = {
        areas: [],
      }
      for (const key in this.areas) {
        content.areas.push(this.areas[key])
      }
      return content
    },
  },

  async mounted () {
    const [, getConfigErr] = await this.$store.dispatch('FindHomePageConfig')
    // if(getConfigErr) this.$message.error(getConfigErr)
    const [, getActError] = await this.$store.dispatch('GetActivity')
    if (getActError) this.$message.error(getActError)
    const [, getCateError] = await this.$store.dispatch('GetProductCategory')
    if (getCateError) this.$message.error(getCateError)
    this.loaded = true
  },
  methods: {
    goSetting () {
      this.$router.push({ name: 'HomeSetting' })
    },

    getConfig (type) {
      return find(this.homePageConfig, { type })
    },
    async refresh () {
      this.$store.dispatch('FindHomePageConfig')
    },
    async handleConfirm () {
      const igonreList = [] // 如果區塊不啟用，忽略不檢查該區塊所有表單欄位
      if (!this.areas.banner.enable) igonreList.push('bannerForm')
      if (!this.areas.productList.enable) igonreList.push('productListForm')
      if (!this.areas.recommand.enable) igonreList.push('recommandForm')
      if (!this.areas.mediaReport.enable) igonreList.push('mediaReportForm')
      if (!this.areas.textStory.enable) igonreList.push('textStoryForm')
      if (!this.areas.marketing.enable) igonreList.push('marketingForm')
      if (!this.areas.youtubeLink.enable) igonreList.push('youtubeLinkForm')
      if (!await this.$store.dispatch('checkAllForm', igonreList)) return
      const content = {
        areas: [],
      }
      forEach(this.areas, data => {
        const objKeys = Object.keys(data)
        console.log(objKeys)
        if (objKeys.length) content.areas.push(data)
      })

      // if(!content.areas.length) return this.$message.warning('請至少設定一個項目！')

      const [, err] = await this.$store.dispatch('updateHomePageSetting', {
        key: 'home',
        content,
      })

      if (err) return this.$message.error(err)
      this.$message.success('更新成功！')
    },
    updateData (data, type) {
      // if(type === 'banner') if(!data.banner.carousel.length) return
      // if(type === 'marketing') if(!data.banner.carousel.length) return
      // console.log(type, data)
      // if(type === 'textStory') if(!data.text.title || !data.text.description) return
      this.$set(this.areas, type, data)
    },
  },
}
</script>

<style lang="scss" scoped>
.message-tab-footer {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  left: 0;
  right: 0;
  gap: 30px;
  box-shadow: 0px -4px 8px rgba(47, 32, 27, 0.04);
  padding: 15px 60px !important;
  background: white;
  z-index: 2;
}

.block-list {
  @apply flex flex-col gap-[35px] mt-[10px];
}
</style>
