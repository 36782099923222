<template>
  <div>
    <a id="mediaReport-block" class="relative" href="#" style=" top: -85px" />
    <SectionTitle hideBtn title="媒體報導" />
    <el-table :data="banners">
      <el-table-column label="報導圖片" align="center">
        <template slot-scope="scope">
          <img :src="imgUrl(scope.row.image)">
        </template>
      </el-table-column>
      <el-table-column label="標題" prop="title" align="center" />
      <el-table-column label="簡述" prop="description" align="center" />
      <el-table-column label="連結" prop="link" align="center" />
    </el-table>
  </div>
</template>

<script>
import { get } from 'lodash'
import { imgSrc } from '@/utils/helper'

export default {
  name: 'MediaReportViewBlock',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    banners () {
      return get(this.data, 'news.carousel', [])
    },
  },
  methods: {
    imgUrl (img) {
      return imgSrc(150, img)
    },
  },
}
</script>
