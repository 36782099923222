<template>
  <div>
    <a id="recommand-block" class="relative" href="#" style=" top: -85px" />
    <SectionTitle hideBtn title="精選推薦" />
    <el-table :data="banners">
      <el-table-column label="連結分類" align="center">
        <template slot-scope="scope">
          <p>{{ typeDict(scope.row) }}</p>
        </template>
      </el-table-column>
      <el-table-column label="使用類別" align="center">
        <template slot-scope="scope">
          <p>{{ cateActivityDict('category', scope.row) }}</p>
        </template>
      </el-table-column>
      <el-table-column label="使用活動" align="center">
        <template slot-scope="scope">
          <p>{{ cateActivityDict('activity', scope.row) }}</p>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { get } from 'lodash'

export default {
  name: 'RecommandViewBlock',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    banners () {
      return get(this.data, 'productTypeList.carousel', [])
    },
  },
  methods: {
    typeDict (row) {
      const type = get(row, 'productType.type', '')
      if (!type) return ''
      const dict = {
        ecommerceCategory: '類別',
        ecommerceActivity: '活動',
      }
      return dict[type]
    },
    cateActivityDict (show, row) {
      const type = get(row, 'productType.type', '')
      if (!type) return '-'
      if (type === 'ecommerceCategory' && show === 'category') {
        return get(row, 'productType.ecommerceCategory.name', '-')
      }
      if (type === 'ecommerceCategory' && show !== 'category') {
        return '-'
      }
      if (type === 'ecommerceActivity' && show === 'activity') {
        return get(row, 'productType.ecommerceActivity.name', '-')
      }
      if (type === 'ecommerceActivity' && show !== 'activity') {
        return '-'
      }
    },
  },
}
</script>

<style>

</style>
